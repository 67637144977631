import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import Pages from './pages/Pages';
import { LoadingProvider } from './context/LoadingContext';
import GlobalLoadingOverlay from './components/GlobalLoadingOverlay/GlobalLoadingOverlay';
import { UserProvider } from './context/UserContext'; 
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import "@fontsource/open-sans";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, Arial, sans-serif",
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <UserProvider>
          <LoadingProvider>
            <GlobalLoadingOverlay />
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<Login />} />
                <Route path="/pages/*" element={<Pages />} />
              </Routes>
            </Router>
            </LoadingProvider>
        </UserProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
