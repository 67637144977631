import { Trial } from '../types/Trial';
import { TrialSummary } from '../types/TrialSummary';
import axiosClient from './axiosClient';

export const getAllTrials = async (): Promise<Trial[]> => {
    const response = await axiosClient.get<Trial[]>('/trial/getTrials');
    return response.data
}

export const createTrial = async (conversationId: string, trainingId: string, userId: string): Promise<Trial> => {
    const response = await axiosClient.post<Trial>(
        '/trial/createTrial',
        {},
        {
            params: {
                conversation_id: conversationId,
                training_id: trainingId,
                user_id: userId
            }
        }
    );
    return response.data;
};

export const getTrialsById = async (trialIds: string[]): Promise<Trial[]> => {
    const response = await axiosClient.get<Trial[]>('/trial/getTrialInList', {
        params: { trial_idsList: trialIds.join(',') },
    });
    return response.data;
};
