// Progress.tsx
import React, { useEffect, useState } from 'react';
import { Trial } from '../../../types/Trial';
import { getAllTrials } from '../../../services/trialService';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import { tableContainerStyles, tableTitleStyles, tableChipStyles, tableScoreStyles } from '../../../assets/styles/styles';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen'; // Import the LoadingScreen component

const Progress: React.FC = () => {
  const navigate = useNavigate();
  const [trials, setTrials] = useState<Trial[]>();
  const [localLoading, setLocalLoading] = useState(true); // Local loading state for Progress component

  useEffect(() => {
    const init = async () => {
      setLocalLoading(true);
      try {
        const allTrials = await getAllTrials();
        setTrials(allTrials);
      } finally {
        setLocalLoading(false);
      }
    };
    init();
  }, []);

  const formatDate = (dateString: string) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }).format(date);
  };

  const handleTitleClick = (id: string) => {
    navigate(`/pages/progress/${id}`);
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* Render LoadingScreen as an overlay if localLoading is true */}
      {localLoading && <LoadingScreen />}

      {/* Main content for Progress component, with reduced opacity while loading */}
      <div style={{ opacity: localLoading ? 0.5 : 1 }}>
        <h2 style={{ textAlign: "left" }}>Attempt Feedback</h2>
        <TableContainer component={Paper} sx={{ ...tableContainerStyles }}>
          <Table sx={{ minWidth: 650 }} aria-label="assignments table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="h6">Title</Typography></TableCell>
                <TableCell><Typography variant="h6">Completed date</Typography></TableCell>
                <TableCell><Typography variant="h6">Due date</Typography></TableCell>
                <TableCell><Typography variant="h6">Status</Typography></TableCell>
                <TableCell><Typography variant="h6">Score</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trials && trials.map((row: Trial) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Typography
                      sx={{ ...tableTitleStyles }}
                      onClick={() => handleTitleClick(row.id)}
                    >
                      {row.training_title}
                    </Typography>
                  </TableCell>
                  <TableCell>{formatDate(row.complete_date)}</TableCell>
                  <TableCell>{formatDate(row.deadline)}</TableCell>
                  <TableCell>
                    <Chip
                      icon={
                        row.status === "pass" ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : row.status === "fail" ? (
                          <CancelIcon style={{ color: 'red' }} />
                        ) : (
                          <RadioButtonUncheckedIcon style={{ color: 'gray' }} />
                        )
                      }
                      label={row.status ?? 'to do'}
                      variant="outlined"
                      sx={{ ...tableChipStyles }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ ...tableScoreStyles }}>
                      {row.score}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Progress;
