import React, { useState } from 'react';
import { Box, Button, Typography, Grid, Paper, Divider, List, ListItem, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './ManageAssignments.css';
import { EmployeeInfo } from '../../../types/EmployeeInfo';
import { AssignmentInfo } from '../../../types/AssignmentInfo';
import { SAMPLEEMPLOYEES } from '../../../constant/SampleEmployees';
import { SAMPLEASSIGNMENTS as assignments } from '../../../constant/SampleAssignments';
import CreateAssignment from '../../../components/CreateAssignment/CreateAssignment';

const ManageAssignments: React.FC = () => {
  const [selectedAssignment, setSelectedAssignment] = useState<AssignmentInfo | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const handleAssignmentClick = (assignment: AssignmentInfo) => {
    setSelectedAssignment(assignment);
  };

  const handleOpenPopover = () => {
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <Box className="container" sx={{ display: 'flex', flexDirection: 'row', height: '100vh', overflow: 'hidden' }}>
      <Box
        className="leftSection"
        sx={{
          width: '220px',
          minWidth: '220px',
          height: 'calc(100vh - 32px)',
          overflowY: 'auto',
          paddingRight: '16px',
          boxSizing: 'border-box',
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          fullWidth
          className="createButton"
          style={{ marginBottom: '16px' }}
          onClick={handleOpenPopover}
        >
          Create New Assignment
        </Button>
        {assignments.map((assignment: AssignmentInfo, index: number) => (
          <Paper
            key={index}
            className="simBox"
            onClick={() => handleAssignmentClick(assignment)}
            elevation={3}
            style={{ marginBottom: '16px' }}
          >
            <Box p={2}>
              <Typography variant="subtitle1" component="div">
                {assignment.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Due: {assignment.dueDate}
              </Typography>
            </Box>
          </Paper>
        ))}
      </Box>

      <Box
        className="rightSection"
        sx={{
          flexGrow: 1,
          height: 'calc(100vh - 32px)',
          overflowY: 'auto',
          paddingLeft: '16px',
          boxSizing: 'border-box',
          minWidth: 0,
        }}
      >
        {selectedAssignment ? (
          <Box>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 600 }}>
              {selectedAssignment.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {selectedAssignment.description}
            </Typography>

            {/* Completed and Not Completed Sections (Stacked) */}
            <Grid container spacing={3}>
              {/* Not Completed List */}
              <Grid item xs={12}>
                <Paper elevation={2} style={{ height: '350px', overflowY: 'auto' }}>
                  <Box p={2} style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                    <Typography variant="subtitle1">Not Completed</Typography>
                    <Divider />
                  </Box>
                  <List>
                    {SAMPLEEMPLOYEES.filter(employee =>
                      employee.assignments.some(a => a.title === selectedAssignment.title && a.status === 'Not Completed')
                    ).map((employee, idx) => (
                      <ListItem key={idx}>
                        <ListItemText primary={employee.name} secondary={`Due Date: ${selectedAssignment.dueDate}`} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>

              {/* Completed List */}
              <Grid item xs={12}>
                <Paper elevation={2} style={{ height: '350px', overflowY: 'auto' }}>
                  <Box p={2} style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                    <Typography variant="subtitle1">Completed</Typography>
                    <Divider />
                  </Box>
                  <List>
                    {SAMPLEEMPLOYEES.filter(employee =>
                      employee.assignments.some(
                        a => a.title === selectedAssignment.title && (a.status === 'Passed' || a.status === 'Failed')
                      )
                    ).map((employee, idx) => {
                      const assignment = employee.assignments.find(a => a.title === selectedAssignment.title);
                      return (
                        <ListItem key={idx}>
                          <ListItemText
                            primary={employee.name}
                            secondary={`Score: ${assignment?.score}% | Due Date: ${assignment?.dueDate}`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography variant="body1" style={{ marginTop: '16px' }}>
            Please select an assignment from the left to see details.
          </Typography>
        )}
      </Box>

      {/* Create Assignment Popover */}
      <CreateAssignment open={popoverOpen} onClose={handleClosePopover} employees={SAMPLEEMPLOYEES.map(employee => employee.name)} />
    </Box>
  );
};

export default ManageAssignments;
