import React, { useEffect } from 'react';
import { fetchTrainingData } from '../../../services/managerService';

const Overview: React.FC = () => {
  useEffect(() => {
    const getData = async () => {
      // const data = await fetchTrainingData();
      // console.log(data);
    }
    getData();
  }, []);
  
  return (
    <div>
      <h1>Overview</h1>
    </div>
  );
};

export default Overview;