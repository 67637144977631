import React, { useState } from 'react';
import { Drawer, Icon, IconButton, List, ListItemButton, ListItemText, FormControlLabel, Switch } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './Sidebar.css';
import { useUser } from '../../context/UserContext';
import { attachAgentRole, attachManagerRole } from '../../services/userService';
import { useNavigate } from 'react-router-dom';

interface SidebarItem {
  text: string;
  path: string;
}

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  onNavigate: (path: string) => void;
  items: SidebarItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar, onNavigate, items }) => {
  const { user } = useUser();
  const [isAgent, setIsAgent] = useState(user?.roles.includes("ROLE_AGENT"));
  const navigate = useNavigate();

  const handleToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggled = event.target.checked;
    setIsAgent(toggled);

    let newUser = null;
    if (toggled) {
      newUser = await attachAgentRole(user?.id!, "2");
    } else {
      newUser = await attachManagerRole(user?.id!, "1");
    }
    
    console.log(user);
    console.log(newUser);
    navigate('/login');
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggleSidebar}
      variant="persistent"
      classes={{ paper: "drawer-paper" }}
    >
      <div className="sidebar-content">
        <IconButton
          onClick={toggleSidebar}
          className="menu-icon-sidebar"
          color="inherit"
        >
          {isOpen ? <ArrowBackIosNewIcon className="sidebar-toggle-icon"/> : <Icon />}
        </IconButton>

        <div className="sidebar-items">
          <List>
            {items.map((item, index) => (
              <ListItemButton key={index} onClick={() => onNavigate(item.path)}>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
            <FormControlLabel
              control={
                <Switch
                  checked={isAgent}
                  onChange={handleToggle}
                  color="primary"
                />
              }
              label={isAgent ? 'User is Agent' : 'User is Manager'}
            />
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
