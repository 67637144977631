import React from 'react';
import './LoadingScreen.css'; // Import the CSS file for the spinner styling

const LoadingScreen: React.FC = () => (
  <div className="loading-background">
    <div className="spinner"></div>
  </div>
);

export default LoadingScreen;
