import { AssignmentInfo } from '../types/AssignmentInfo';

export const SAMPLEASSIGNMENTS: AssignmentInfo[] = [
  {
    id: 6,
    title: 'Simulation 6',
    dueDate: 'Fri, Oct. 25, 2024 5:00 PM EST',
    description: 'Customer reports an unexpected charge on their recent bill and demands an immediate explanation and refund.',
    goals: []
  },
  {
    id: 5,
    title: 'Simulation 5',
    dueDate: 'Wed, Oct. 23, 2024 5:00 PM EST',
    description: 'Customer is new to the service and is having difficulty understanding the billing cycles and available plans.',
    goals: []
  },
  {
    id: 4,
    title: 'Simulation 4',
    dueDate: 'Mon, Oct. 21, 2024 5:00 PM EST',
    description: 'Customer wants to upgrade their current service plan but is frustrated by potential early termination fees.',
    goals: []
  },
  {
    id: 3,
    title: 'Simulation 3',
    dueDate: 'Fri, Oct. 18, 2024 5:00 PM EST',
    description: 'Customer complains about frequent internet outages and requests compensation for the ongoing disruptions.',
    goals: []
  },
  {
    id: 2,
    title: 'Simulation 2',
    dueDate: 'Wed, Oct. 16, 2024 5:00 PM EST',
    description: 'Customer is struggling to access their online account after multiple failed login attempts and needs assistance.',
    goals: []
  },
  {
    id: 1,
    title: 'Simulation 1',
    dueDate: 'Mon, Oct. 14, 2024 5:00 PM EST',
    description: 'Customer demands a refund, claiming that the service quality has not met their expectations and they feel misled.',
    goals: []
  },
];