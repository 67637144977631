import { SxProps } from '@mui/material';

export const tableContainerStyles: SxProps = {
  marginTop: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
};

export const tableTitleStyles: SxProps = {
  color: '#0077b6',
  cursor: 'pointer',
  textDecoration: 'none',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  },
};

export const tableChipStyles: SxProps = {
  fontWeight: 'bold',
};

export const tableScoreStyles: SxProps = {
  fontWeight: 'bold',
  color: '#333',
};
