import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import MicOffIcon from '@mui/icons-material/MicOff';
import PauseIcon from '@mui/icons-material/Pause';
import CallEndIcon from '@mui/icons-material/CallEnd';
import './CallScreen.css';
import { endConversation, getSpeechToken, sendUserMessage, startConversation } from '../../services/speechAuthorizationService';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { Buffer } from 'buffer';
import { useUser } from '../../context/UserContext';
import { createTrial } from '../../services/trialService';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen'; 
import { TrainingScenario } from '../../types/TrainingScenario';
import { VoiceStyle } from '../../types/VoiceStyle';
import { hasSpeechEnded, audioUpload } from '../../services/audioService';
import { useMicrophoneRecorder } from '../../hooks/useMicrophoneRecorder';

interface CallScreenProps {
  onBack: () => void;
  assignment: TrainingScenario;
  voiceStyle: VoiceStyle;
}

const CallScreen: React.FC<CallScreenProps> = ({ onBack, assignment, voiceStyle }) => {
  const { user } = useUser();
  const hasStartedConversation = useRef(false);

  const audioConfigRef = {
    current: SpeechSDK.AudioConfig.fromDefaultMicrophoneInput(),
  };
  const speechConfigRef = useRef<SpeechSDK.SpeechConfig | null>();
  const recognizerRef = useRef<SpeechSDK.SpeechRecognizer | null>(null);
  const currentSourceRef = useRef<AudioBufferSourceNode | null>(null);
  const currentAgentTextRef = useRef<string>("");
  const conversationId = useRef("");
  const botEndTime = useRef<number>();

  const navigate = useNavigate();

  const [isListening, setIsListening] = useState(false); 
  const [isBotSpeaking, setIsBotSpeaking] = useState(false); 
  const [showEndCallConfirmation, setShowEndCallConfirmation] = useState(false); 
  const [loading, setLoading] = useState(true); 
  const [endLoading, setEndLoading] = useState(false); 

  const handleEndConversation = async (botBuffer: Buffer) => {
    try {
      const safeBuffer = Buffer.from(botBuffer);
      await uploadBuffer(safeBuffer, `${conversationId.current}/agent-speech.wav`);
      const result = await endConversation(conversationId.current);
      createTrial(result.id, assignment.training.id, user!.id);
    } catch (error) {
      console.log(error);
    }
    navigate(`/pages/progress`);
  };

  const { startRecording, stopRecording } = useMicrophoneRecorder(handleEndConversation);

  useEffect(() => {
    let shouldCleanup = false; // prevents cleanup on initial render
    const init = async () => {
      if (!hasStartedConversation.current) {
        hasStartedConversation.current = true;
        const speechToken = await getSpeechToken();
        if (speechToken) {
          const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(speechToken, "westus2");
          speechConfig.speechSynthesisOutputFormat = SpeechSDK.SpeechSynthesisOutputFormat.Riff16Khz16BitMonoPcm;
          speechConfigRef.current = speechConfig;
          recognizerRef.current = new SpeechSDK.SpeechRecognizer(
            speechConfigRef.current!,
            audioConfigRef.current
          );
          const conversation = await startConversation(assignment.training.id);
          conversationId.current = conversation.conversationid;
          botEndTime.current = Date.now();
          startRecording();
          startListening();
          setLoading(false); 
        }
        shouldCleanup = true;
      }
    };
    init();
    return () => {
      if (shouldCleanup) {
        endSpeech();
      }
    }
  }, [assignment.training.scenario_id]);

  function endSpeech() {
    if (recognizerRef.current && currentSourceRef.current) {
      setEndLoading(true); 
      stopRecording();
      if (recognizerRef.current) {
        recognizerRef.current.close();
      }
      if (currentSourceRef.current) {
        currentSourceRef.current.stop();
      }
      currentSourceRef.current = null; 
      recognizerRef.current = null;
      speechConfigRef.current = null;
    }
  }

  function getLastSentence(text: string) {
    const lastIndex = text.lastIndexOf('.');
    if (lastIndex === text.length - 1) {
      const prevIndex = text.slice(0, -1).lastIndexOf('.');
      return prevIndex === -1 ? text : text.substring(prevIndex + 1);
    }
    return lastIndex === -1 ? text : text.substring(lastIndex + 1);
  }

  async function startListening() {
    setIsListening(true);
    if (!speechConfigRef.current) {
      console.log("Speech recognition ended.");
      return;
    }
    let timer: ReturnType<typeof setTimeout>;
    recognizerRef.current!.startContinuousRecognitionAsync();

    recognizerRef.current!.recognizing = (s, e) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        console.log("Timer reached 3 seconds. Checking end of speech.");
        const lastSentence = getLastSentence(currentAgentTextRef.current);
  
        const done = await hasSpeechEnded(lastSentence, conversationId.current);
        if (done) {
          onAgentSpeechEnd();
        } else {
          timer = setTimeout(() => {
            onAgentSpeechEnd();
          }, 4000);
        }
      }, 2000);
    };

    recognizerRef.current!.recognized = async (s, e) => {
      const result = e.result;
      if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
        currentAgentTextRef.current += " " + result.text;
      } else if (result.reason === SpeechSDK.ResultReason.NoMatch) {
        console.log("No speech recognized.");
      } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
        const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
        console.error("Speech recognition canceled. Reason: " + cancellationDetails.reason);
        console.error("Error details: " + cancellationDetails.errorDetails);
      } else {
        console.error("Recognition error: " + result.errorDetails);
      }
    };
  }

  async function onAgentSpeechEnd() {
    setIsListening(false);
    recognizerRef.current!.stopContinuousRecognitionAsync();
    try {
        const response = await sendUserMessage({
          conversationid: conversationId.current,
          msgbody: currentAgentTextRef.current,
          msgtype: "user"
        });
        currentAgentTextRef.current = ""; // Clear text after sending message
        handleBotResponse(response, speechConfigRef.current);
    } catch (err) {
      console.warn("Ignoring empty agent input.");
    }
  }

  function handleBotResponse(botData: any, speechConfig: any) {
    const ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xml:lang='en-US'>
      <voice name='${voiceStyle.voiceName}'>
        <mstts:express-as style='${botData.emotion}'>${botData.msgbody}</mstts:express-as>
      </voice>
    </speak>`;

    const nullAudioStream = SpeechSDK.AudioOutputStream.createPullStream();
    const mutedAudioConfig = SpeechSDK.AudioConfig.fromStreamOutput(nullAudioStream);
    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, mutedAudioConfig);
    
    const botStartTime = Date.now();
    synthesizer.speakSsmlAsync(ssml, (result: SpeechSDK.SpeechSynthesisResult) => {
      if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
        try {
          const elapsedTimeSeconds = (botStartTime - botEndTime.current!) / 1000;
          const audioBuffer = Buffer.from(result.audioData);
          uploadBuffer(audioBuffer, `${conversationId.current}/bot/speechpart-${botEndTime.current}.wav`, elapsedTimeSeconds);
        } catch (error) {
          console.log("Upload failed", error);
        }
        playAudio(result.audioData);
        console.log("Audio played successfully.");
      } else {
        console.error("Error synthesizing speech:", result.errorDetails);
      }
    });
  }

  function playAudio(audioData: ArrayBuffer) {
    setIsBotSpeaking(true);
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

    audioContext.decodeAudioData(audioData, buffer => {
      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      currentSourceRef.current = source;

      source.onended = () => {
        setIsBotSpeaking(false);
        botEndTime.current = Date.now();
        console.log("done");
        startListening();
      };

      source.start(0);
    });
  }

  const uploadBuffer = async (data: Buffer, blobName: string, silenceLength: number = 0) => {
    const currentBuffer = Buffer.from(data);
    const formData = new FormData();
    formData.append("blobName", blobName);
    formData.append("silenceLength", silenceLength.toString());
    formData.append("audioData", new Blob([currentBuffer], { type: "audio/wav" }));
    
    await audioUpload(formData);
}



  if (endLoading) {
    endSpeech();
  }

  if (loading || endLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="call-screen-container">
      <Box className="call-screen-main">
        <Box className="call-screen-placeholder">
          <div className="profile-container">
            <div className="rounded-square">
              <div className={`profile-icon ai ${isBotSpeaking ? 'speaking' : ''}`}>AI</div>
            </div>
            <div className="rounded-square">
              <div className={`profile-icon you ${isListening ? 'speaking' : ''}`}>You</div>
            </div>
          </div>
        </Box>
        <Box className="training-details">
          <Typography variant="body1"><strong>Training Description:</strong></Typography>
          <Typography variant="body1" className="training-description">
            {assignment.training.training_description}
          </Typography>
        </Box>
      </Box>

      <Box className="call-options-bar">
        <Button variant="contained" color="secondary" startIcon={<MicOffIcon />}>Mute</Button>
        <Button variant="contained" color="secondary" startIcon={<PauseIcon />}>Hold</Button>
        <Button
          onClick={() => setShowEndCallConfirmation(true)}
          variant="contained"
          color="error"
          startIcon={<CallEndIcon />}
        >
          Hang Up
        </Button>
      </Box>

      <Dialog
        open={showEndCallConfirmation}
        onClose={() => setShowEndCallConfirmation(false)}
        aria-labelledby="confirm-end-call-title"
        aria-describedby="confirm-end-call-description"
      >
        <DialogTitle id="confirm-end-call-title">Confirm End Call</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-end-call-description">
            Do you want to end this call?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEndCallConfirmation(false)} variant="outlined">No</Button>
          <Button onClick={endSpeech} variant="contained" color="error">Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CallScreen;
