// Assignments.tsx
import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import AssignmentTable from '../../../components/AssignmentTable/AssignmentTable';
import { useTrainingScenarios } from '../../../context/TrainingScenarioContext';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import "./Assignments.css";

const Assignments: React.FC = () => {
  const { trainingScenarios } = useTrainingScenarios();
  const [localLoading, setLocalLoading] = useState(true); // Local loading state for Assignments

  useEffect(() => {
    // If trainingScenarios is still null, that means it's loading
    if (trainingScenarios === null) {
      setLocalLoading(true);
    } else {
      setLocalLoading(false); // Stop loading once data is available
    }
  }, [trainingScenarios]);

  if (localLoading) {
    return <LoadingScreen />; // Display loading screen while data is loading
  }

  return (
    <Container className="assignments-container" sx={{ marginTop: '40px', position: 'relative' }}>
      <Box sx={{ textAlign: 'left', marginBottom: '20px' }}>
        <Typography component="h1" sx={{ fontWeight: 'bold', fontSize: '40px' }}>
          Assigned Training
        </Typography>
      </Box>
      <AssignmentTable />
    </Container>
  );
};

export default Assignments;
