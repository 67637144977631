import React, { useEffect, useState } from 'react';
import AssignmentDetail from '../../../../components/AssignmentDetail/AssignmentDetail';
import CallScreen from '../../../../components/CallScreen/CallScreen';
import { useParams, useNavigate } from 'react-router-dom';
import './AssignmentPage.css';
import { useTrainingScenarios } from '../../../../context/TrainingScenarioContext';
import { VoiceStyle } from '../../../../types/VoiceStyle';
import { TrainingScenario } from '../../../../types/TrainingScenario';
import { getVoiceStyle } from '../../../../services/voiceStyleService';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';

const AssignmentPage: React.FC = () => {
  const { trainingScenarios, getTrainingScenario } = useTrainingScenarios();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  
  const [isInCall, setIsInCall] = useState(false);
  const [voiceStyle, setVoiceStyle] = useState<VoiceStyle>();
  const [localLoading, setLocalLoading] = useState(true); // Local loading state
  const [assignment, setAssignment] = useState<TrainingScenario | undefined>(undefined); // State for assignment

  useEffect(() => {
    // Set assignment only once trainingScenarios are loaded
    if (trainingScenarios !== null) {
      const foundAssignment = getTrainingScenario(id!);
      setAssignment(foundAssignment); // Update assignment state
      setLocalLoading(false); // Stop loading once assignment is retrieved
    }
  }, [trainingScenarios, id, getTrainingScenario]);

  useEffect(() => {
    // Fetch voice style, but do not delay the loading screen for AssignmentDetail
    const setAssignmentStyle = async (voiceStyleId: string) => {
      const assignmentVoice = await getVoiceStyle(voiceStyleId);
      setVoiceStyle(assignmentVoice);
    };
    
    if (assignment && assignment.training.voicestyle_id) {
      setAssignmentStyle(assignment.training.voicestyle_id);
    }
  }, [assignment]);

  // Show loading screen if still loading
  if (localLoading) {
    return <LoadingScreen />;
  }

  if (!assignment) {
    return <div>Assignment not found</div>;
  }

  const handleStartConversation = () => {
    setIsInCall(true);
  };

  const handleBack = () => {
    if (isInCall) {
      setIsInCall(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="assignment-page-container">
      {isInCall ? (
        <CallScreen onBack={handleBack} assignment={assignment} voiceStyle={voiceStyle!} />
      ) : (
        <AssignmentDetail onStartConversation={handleStartConversation} assignment={assignment} />
       )}
    </div>
  );
};

export default AssignmentPage;
