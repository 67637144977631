import { VoiceStyle } from '../types/VoiceStyle';
import axiosClient from './axiosClient';

export const getVoiceStyle = async (id: string): Promise<VoiceStyle> => {
    const response = await axiosClient.get<VoiceStyle>('/voicestyle',
        {
            params: { id }
        }
    );
    return response.data;
}