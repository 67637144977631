// src/components/Banner.tsx
import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import './Banner.css';

interface BannerProps {

}

const Banner: React.FC<BannerProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  //const restrictedPaths: RegExp[] = [/^\/pages\/assignments\/.*/];

  //const showBackButton = !restrictedPaths.some((regex) => regex.test(location.pathname));

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <header className="banner">
      <IconButton onClick={handleBackClick} className="back-button-banner" color="inherit">
        <ArrowBackIcon />
      </IconButton>
    </header>
  );
};

export default Banner;
