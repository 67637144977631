import { Feedback } from '../types/Feedback';
import axiosClient from './axiosClient';

export const generateFeedback = async (conversationId: string, trainingId: string, agentId: string): Promise<Feedback> => {
    const response = await axiosClient.post<Feedback>('/feedback/generateFeedback', 
        {
            "conversation_id": conversationId,
            "training_id": trainingId,
            "agent_id": agentId
        }
    );
    return response.data;
};

export const getFeedback = async (feedbackId: string): Promise<Feedback> => {
    const response = await axiosClient.get<Feedback>('/feedback/getfeedback',
        {
            params: {
                "feedback_id": feedbackId
            }
        }
    );
    return response.data;
};