import React, { createContext, useContext, useState, useCallback } from 'react';

type LoadingContextType = {
  globalLoading: boolean;
  componentLoadingStates: { [key: string]: boolean };
  setGlobalLoading: (isLoading: boolean) => void;
  setComponentLoading: (key: string, isLoading: boolean) => void;
};

// Define the context with an undefined initial value
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

// Update the LoadingProvider to accept children
export const LoadingProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [globalLoading, setGlobalLoading] = useState(false);
  const [componentLoadingStates, setComponentLoadingStates] = useState<{ [key: string]: boolean }>({});

  const setComponentLoading = useCallback((key: string, isLoading: boolean) => {
    setComponentLoadingStates(prevState => ({
      ...prevState,
      [key]: isLoading,
    }));
  }, []);

  return (
    <LoadingContext.Provider value={{ globalLoading, componentLoadingStates, setGlobalLoading, setComponentLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

// Custom hook to use the LoadingContext
export const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  if (!context) throw new Error('useLoadingContext must be used within a LoadingProvider');
  return context;
};
