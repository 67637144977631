import React, { useState } from 'react';
import {
  Button,
  TextField,
  MenuItem,
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Select,
  Checkbox,
  InputLabel,
  FormControl,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import './CreateAssignment.css';

type Props = {
  open: boolean;
  onClose: () => void;
  employees: string[];
};

type Goal = {
  id: number;
  text: string;
};

type Weight = {
  id: number;
  title: string;
  value: number;
};

const CreateAssignment: React.FC<Props> = ({ open, onClose, employees }) => {
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [goals, setGoals] = useState<Goal[]>([{ id: 1, text: '' }]);
  const [weights, setWeights] = useState<Weight[]>([{ id: 1, title: '', value: 1 }]);
  const [difficulty, setDifficulty] = useState<number>(1);

  const handleSelectEmployee = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    setSelectedEmployees(value);
  };

  const handleAddGoal = () => {
    setGoals([...goals, { id: goals.length + 1, text: '' }]);
  };

  const handleRemoveGoal = (id: number) => {
    setGoals(goals.filter((goal) => goal.id !== id));
  };

  const handleGoalChange = (id: number, value: string) => {
    setGoals(goals.map((goal) => (goal.id === id ? { ...goal, text: value } : goal)));
  };

  const handleAddWeight = () => {
    setWeights([...weights, { id: weights.length + 1, title: '', value: 1 }]);
  };

  const handleRemoveWeight = (id: number) => {
    setWeights(weights.filter((weight) => weight.id !== id));
  };

  const handleWeightChange = (id: number, field: 'title' | 'value', value: string) => {
    setWeights(
      weights.map((weight) =>
        weight.id === id
          ? {
              ...weight,
              [field]: field === 'value' ? (value === '' ? value : Math.max(1, Math.min(10, Number(value)))) : value,
            }
          : weight
      )
    );
  };

  const handleClose = () => {
    // Reset form fields when popover is closed
    setSelectedEmployees([]);
    setName('');
    setDate('');
    setDescription('');
    setGoals([{ id: 1, text: '' }]);
    setWeights([{ id: 1, title: '', value: 1 }]);
    setDifficulty(1);
    onClose();
  };

  if (!open) return null;

  return (
    <div className="backdrop">
      <Paper className="popover-container" sx={{ width: '60%', maxHeight: '80vh', overflowY: 'auto', padding: '24px' }}>
        <Box className="header">
          <Typography variant="h6">Create New Assignment</Typography>
        </Box>
        <Grid container spacing={2} className="popover-content">
          <Grid item xs={12}>
            <TextField label="Name" variant="outlined" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Date" variant="outlined" fullWidth value={date} onChange={(e) => setDate(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Description" variant="outlined" fullWidth multiline rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
          </Grid>

          {/* Goals Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Goals</Typography>
            {goals.map((goal, index) => (
              <Box key={goal.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                  label={`Goal ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  value={goal.text}
                  onChange={(e) => handleGoalChange(goal.id, e.target.value)}
                />
                {goals.length > 1 && (
                  <IconButton onClick={() => handleRemoveGoal(goal.id)}>
                    <RemoveIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={handleAddGoal}>
              Add Goal
            </Button>
          </Grid>

          {/* Weights Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Weights (Criteria)</Typography>
            {weights.map((weight, index) => (
              <Box key={weight.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                  label={`Weight Title ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  value={weight.title}
                  onChange={(e) => handleWeightChange(weight.id, 'title', e.target.value)}
                  inputProps={{ maxLength: 2 }}
                />
                <TextField
                  label="Value"
                  variant="outlined"
                  type="number"
                  value={weight.value === 0 ? '' : weight.value}
                  onChange={(e) => handleWeightChange(weight.id, 'value', e.target.value)}
                  inputProps={{ min: 1, max: 10 }}
                  sx={{ width: '100px', ml: 2 }}
                />
                {weights.length > 1 && (
                  <IconButton onClick={() => handleRemoveWeight(weight.id)}>
                    <RemoveIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={handleAddWeight}>
              Add Weight
            </Button>
          </Grid>

          {/* Difficulty Section */}
          <Grid item xs={12}>
            <TextField
              label="Difficulty"
              variant="outlined"
              type="number"
              fullWidth
              value={difficulty}
              onChange={(e) => setDifficulty(Math.min(5, Math.max(1, Number(e.target.value))))}
              inputProps={{ min: 1, max: 5, maxLength: 1 }}
            />
          </Grid>

          {/* Assign Employees Section */}
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Assign to Employees</InputLabel>
              <Select
                multiple
                value={selectedEmployees}
                onChange={handleSelectEmployee}
                label="Assign to Employees"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(selected as string[]).map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee} value={employee}>
                    <Checkbox checked={selectedEmployees.indexOf(employee) > -1} />
                    {employee}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Actions */}
        <Box className="actions">
          <Button variant="outlined" onClick={handleClose} className="cancel-button">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleClose} className="submit-button">
            Submit
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default CreateAssignment;
