import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useUser } from './UserContext';
import { TrainingScenario } from '../types/TrainingScenario';
import { getByUser } from '../services/trainingScenarioService';

interface TrainingScenarioContextType {
    trainingScenarios: TrainingScenario[] | null;
    getTrainingScenario: (id: string) => TrainingScenario | undefined;
}

const TrainingScenarioContext = createContext<TrainingScenarioContextType | undefined>(undefined);

export const TrainingScenarioProvider = ({ children }: { children: ReactNode } ) => {
    const { user } = useUser();
    const [trainingScenarios, setTrainingScenarios] = useState<TrainingScenario[] | null>(null);

    useEffect(() => {
        const initTrainings = async () => {
            if (user) {
                const trainingList = await getByUser(user?.id!);
                setTrainingScenarios(trainingList);
            }
        }
        initTrainings();
    }, [user]);

    const getTrainingScenario = (id: string) => trainingScenarios?.find(trainingScenario => trainingScenario.training.id === id);

    return (
        <TrainingScenarioContext.Provider value={{ trainingScenarios, getTrainingScenario }}>
            {children}
        </TrainingScenarioContext.Provider>
    );
};

export const useTrainingScenarios = () => {
    const context = useContext(TrainingScenarioContext);
    if (!context) {
        throw new Error("useTrainingScenarios must be used within a TrainingScenario Provider");
    }
    return context;
}
