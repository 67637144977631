import { Conversation, ConversationHistory } from '../types/Conversation';
import { GetResponseRequest } from '../types/GetResponseRequest';
import { GetResponseResponse } from '../types/GetResponseResponse';
import { SpeechToken } from '../types/SpeechToken';
import axiosClient from './axiosClient';

export const getSpeechToken = async (): Promise<string> => {
    const response = await axiosClient.post<SpeechToken>('/getSpeechToken',
        {}
    );
    return response.data.token;
}

export const startConversation = async (trainingId: string): Promise<Conversation> => {
    const response = await axiosClient.post<Conversation>('/startConversation',
        {
            "trainingId": trainingId
        }
    );
    return response.data;
}

export const endConversation = async (conversationId: string): Promise<ConversationHistory> => {
    const response = await axiosClient.post<ConversationHistory>('/endConversation',
        {
            "conversationid": conversationId
        }
    );
    return response.data;
}

export const sendUserMessage = async (responseRequest: GetResponseRequest): Promise<GetResponseResponse> => {
    const response = await axiosClient.post<GetResponseResponse>('/getResponse', responseRequest);
    return response.data;
}