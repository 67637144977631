import { User } from '../types/User';
import axiosClient from './axiosClient';

export const attachAgentRole = async (userId: string, agentId: string): Promise<User> => {
    const response = await axiosClient.put<User>('/user/attachAgentRole',
        {},
        {
            params: {
                "user_id": userId,
                "agent_id": agentId
            }
        }
    );
    return response.data;
}

export const attachManagerRole = async (userId: string, managerId: string): Promise<User> => {
    const response = await axiosClient.put<User>('/user/attachManagerRole',
        {},
        {
            params: {
                "user_id": userId,
                "manager_id": managerId
            }
        }
    );
    return response.data;
}
