import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  Collapse,
  Paper,
  Typography,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from 'react-router-dom';
import { tableContainerStyles, tableTitleStyles, tableChipStyles, tableScoreStyles } from '../../assets/styles/styles';
import { useTrainingScenarios } from '../../context/TrainingScenarioContext';
import { TrialSummary } from "../../types/TrialSummary";
import "./AssignmentTable.css"


const AssignmentTable: React.FC = () => {
  const navigate = useNavigate();
  let { trainingScenarios } = useTrainingScenarios();

  if (trainingScenarios === null) {
    trainingScenarios = [];
  }
  
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8;
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [subTableSortState, setSubTableSortState] = useState<Record<string, { order: "asc" | "desc"; orderBy: string }>>({});

  const preprocessTrainingScenarios = (scenarios: typeof trainingScenarios) => {
    if (scenarios) {
      return scenarios
      .filter(
        (scenario) =>
          scenario.deadline &&
          scenario.scenario &&
          scenario.status &&
          scenario.training &&
          scenario.training.id &&
          scenario.training.training_title
      )
      .map((scenario) => ({
        ...scenario,
        trialSummary_list: scenario.trialSummary_list.filter(
          (trial) =>
            trial.complete_date &&
            trial.end_time &&
            trial.start_time &&
            trial.feedback_id &&
            trial.score
        ),
      }));
    } else return [];
  };

  const handleReportClick = (id: string) => {
    navigate(`/pages/progress/${id}`);
  };

  const handleSort = (column: string) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  const handleSubTableSort = (parentId: string, column: string) => {
    setSubTableSortState((prev) => {
      const currentSort = prev[parentId] || { order: "asc", orderBy: "" };
      const isAsc = currentSort.orderBy === column && currentSort.order === "asc";
      return {
        ...prev,
        [parentId]: { order: isAsc ? "desc" : "asc", orderBy: column },
      };
    });
  };

  const validTrainingScenarios = preprocessTrainingScenarios(trainingScenarios);

  const presortedTrainingScenarios = [...validTrainingScenarios].sort((a, b) => {
    const statusGroupA = a.status === "Pass" ? 1 : 0; // 1 = Passed, 0 = Not Passed
    const statusGroupB = b.status === "Pass" ? 1 : 0;
    return statusGroupA - statusGroupB; // "Not Passed" comes first
  });
  
  

  const DateParse = (date: string): string => 
    date.substring(0, 10) + 'T' + date.substring(11, 19);
  
  const ElapsedTime = (time: number): string => {
    const hours: number = Math.floor(time / (1000 * 60 * 60));
    const minutes: number = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((time % (1000 * 60)) / 1000);
  
    const result: string[] = [];
  
    if (hours > 0) result.push(`${hours}h`);
    if (minutes > 0) result.push(`${minutes}m`);
    if (seconds > 0) result.push(`${seconds}s`);
  
    return result.length > 0 ? result.join(' ') : '0s';
  };
  
  const sortedRows = [...presortedTrainingScenarios].sort((a, b) => {
    let primaryComparison = 0;
  
    switch (orderBy) {
      case "title":
        primaryComparison = a.training.training_title.localeCompare(b.training.training_title);
        break;
      case "dueDate":
        primaryComparison = Date.parse(a.deadline) - Date.parse(b.deadline);
        break;
      case "lastAttemptDate":
        const lastAttemptA = a.trialSummary_list.length > 0
          ? Date.parse(a.trialSummary_list[a.trialSummary_list.length - 1].complete_date)
          : 0;
        const lastAttemptB = b.trialSummary_list.length > 0
          ? Date.parse(b.trialSummary_list[b.trialSummary_list.length - 1].complete_date)
          : 0;
        primaryComparison = lastAttemptA - lastAttemptB;
        break;
      case "attempts":
        primaryComparison = a.trialSummary_list.length - b.trialSummary_list.length;
        break;
      case "status":
        const statusGroupA = a.status === "Pass" ? 1 : 0; // 1 = Passed, 0 = Not Passed
        const statusGroupB = b.status === "Pass" ? 1 : 0;
        primaryComparison = statusGroupA - statusGroupB;
        break;
      default:
        break;
    }
  
    // if primary comparison is tied, apply tiebreakers
    if (primaryComparison !== 0) return order === "asc" ? primaryComparison : -primaryComparison;
  
    // tiebreakers in order: status > due date > title > most recent attempt > number of attempts
    const statusComparison = (a.status === "Pass" ? 1 : 0) - (b.status === "Pass" ? 1 : 0);
    if (statusComparison !== 0) return statusComparison;
  
    const dueDateComparison = Date.parse(a.deadline) - Date.parse(b.deadline);
    if (dueDateComparison !== 0) return dueDateComparison;
  
    const titleComparison = a.training.training_title.localeCompare(b.training.training_title);
    if (titleComparison !== 0) return titleComparison;
  
    const lastAttemptComparison = (a.trialSummary_list.length > 0
      ? Date.parse(a.trialSummary_list[a.trialSummary_list.length - 1].complete_date)
      : 0) - (b.trialSummary_list.length > 0
      ? Date.parse(b.trialSummary_list[b.trialSummary_list.length - 1].complete_date)
      : 0);
    if (lastAttemptComparison !== 0) return lastAttemptComparison;
  
    return a.trialSummary_list.length - b.trialSummary_list.length;
  });

  const sortedSubRows = (rows: TrialSummary[], parentId: string) => {
    const preSorted = [...rows].sort((a, b) => {
      const dateA = Date.parse(a.complete_date);
      const dateB = Date.parse(b.complete_date);
      return dateA - dateB;
    });
  
    const { order, orderBy } = subTableSortState[parentId] || { order: "asc", orderBy: "" };
  
    return preSorted.sort((a, b) => {
      let aValue: any, bValue: any;
  
      switch (orderBy) {
        case "date":
          aValue = a.complete_date.substring(0, 10);
          bValue = b.complete_date.substring(0, 10);
          break;
  
        case "score":
          aValue = Number(a.score);
          bValue = Number(b.score);
          break;
  
        case "timeElapsed":
          const elapsedTimeA = Date.parse(a.end_time) - Date.parse(a.start_time);
          const elapsedTimeB = Date.parse(b.end_time) - Date.parse(b.start_time);
          aValue = elapsedTimeA;
          bValue = elapsedTimeB;
          break;
  
        default:
          return 0;
      }
  
      if (order === "asc") return aValue > bValue ? 1 : -1;
      return aValue < bValue ? 1 : -1;
    });
  };

  const handleAssignmentClick = (id: string) => {
    navigate(`/pages/assignments/${id}`);
  };

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * rowsPerPage;
  const visibleRows = sortedRows.slice(startIndex, startIndex + rowsPerPage);
  
  return (
    <>
    <Paper>
      <TableContainer className="styled-table-container">
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow className="header-row">
              <TableCell className="header-cell"width="40px"/>
              <TableCell className="header-cell" width="230px">
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={() => handleSort("title")}
                >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell className="header-cell" width="145px">
                <TableSortLabel
                  active={orderBy === "dueDate"}
                  direction={orderBy === "dueDate" ? order : "asc"}
                  onClick={() => handleSort("dueDate")}
                >
                  Due Date
                </TableSortLabel>
              </TableCell>
              <TableCell className="header-cell" width="215px">
                <TableSortLabel
                  active={orderBy === "attempts"}
                  direction={orderBy === "attempts" ? order : "asc"}
                  onClick={() => handleSort("attempts")}
                >
                  Attempts
                </TableSortLabel>
              </TableCell>
              <TableCell className="header-cell" width="180px">
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={orderBy === "status" ? order : "asc"}
                  onClick={() => handleSort("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell className="header-cell" width="200px">
                <TableSortLabel
                  active={orderBy === "lastAttemptDate"}
                  direction={orderBy === "lastAttemptDate" ? order : "asc"}
                  onClick={() => handleSort("lastAttemptDate")}
                >
                  Last Attempt Date
                </TableSortLabel>
              </TableCell>
              <TableCell className="header-cell" width="200px"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => (
              <React.Fragment key={row.training.id}>
                <TableRow className="body-row">
                  <TableCell className="body-cell"/>
                  <TableCell className="body-cell">{row.training.training_title}</TableCell>
                  <TableCell className="body-cell">{row.deadline.substring(0, 10)}</TableCell>
                  <TableCell className="body-cell">
                    {row.trialSummary_list.length > 0 ? (
                      <Button
                        onClick={() => toggleRow(row.training.id)}
                        aria-expanded={!!expandedRows[row.training.id]}
                        className={`attempts-button ${!!expandedRows[row.training.id] ? "expanded" : "default"}`}
                      >
                        <span style={{ marginRight: "20px" }}>
                          {!!expandedRows[row.training.id] ? "Hide Attempts" : "Show Attempts"}
                        </span>
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          className={`attempts-icon ${expandedRows[row.training.id] ? "expanded" : "collapsed"}`}
                        />
                      </Button>
                    ) : (
                      "—"
                    )}
                  </TableCell>
                  <TableCell className="body-cell">
                    <span
                      className={`pill status ${row.status === "Pass" ? "pill-passed" : "pill-failed"}`}
                    >
                      {row.status === "Pass" ? "Passed" : "Not Passed"}
                    </span>
                  </TableCell>
                  <TableCell className="body-cell">
                    {row.trialSummary_list.length > 0 ? row.trialSummary_list[row.trialSummary_list.length - 1].complete_date.substring(0, 10) : "—"}
                  </TableCell>
                  <TableCell className="body-cell">
                    <Button className="action-button"
                      onClick={() => handleAssignmentClick(row.training.id)}
                    >
                      Open Simulation <KeyboardArrowRightIcon className="open-sim-arrow"/>
                    </Button>
                  </TableCell>
                </TableRow>
                {row.trialSummary_list.length > 0 && expandedRows[row.training.id] && (
                  <TableRow className="collapsible-row">
                    <TableCell className="body-cell" colSpan={7} style={{ padding: 0 }}>
                      <Collapse in={expandedRows[row.training.id]} timeout="auto" unmountOnExit>
                        <Table size="small">
                          <TableHead>
                            <TableRow className="attempt-header-row">
                              <TableCell className="attempt-header-cell" width="50px"/>
                              <TableCell className="attempt-header-cell">Attempt No.</TableCell>
                              <TableCell className="attempt-header-cell">
                                <TableSortLabel
                                  active={subTableSortState[row.training.id]?.orderBy === "date"}
                                  direction={subTableSortState[row.training.id]?.orderBy === "date" ? subTableSortState[row.training.id].order : "asc"}
                                  onClick={() => handleSubTableSort(row.training.id, "date")}
                                >
                                  Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className="attempt-header-cell">
                                <TableSortLabel
                                  active={subTableSortState[row.training.id]?.orderBy === "timeElapsed"}
                                  direction={subTableSortState[row.training.id]?.orderBy === "timeElapsed" ? subTableSortState[row.training.id].order : "asc"}
                                  onClick={() => handleSubTableSort(row.training.id, "timeElapsed")}
                                >
                                  Time Elapsed
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className="attempt-header-cell">
                                <TableSortLabel
                                  active={subTableSortState[row.training.id]?.orderBy === "score"}
                                  direction={subTableSortState[row.training.id]?.orderBy === "score" ? subTableSortState[row.training.id].order : "asc"}
                                  onClick={() => handleSubTableSort(row.training.id, "score")}
                                >
                                  Score
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className="attempt-header-cell"/>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedSubRows(row.trialSummary_list, row.training.id).map((trial: TrialSummary, index: number) => (
                              <TableRow className="attempt-row" key={index}>
                                <TableCell className="attempt-cell"/>
                                <TableCell className="attempt-cell">{index + 1}</TableCell>
                                <TableCell className="attempt-cell">{trial.complete_date.substring(0, 10)}</TableCell>
                                <TableCell className="attempt-cell">{ElapsedTime(Date.parse(DateParse(trial.end_time)) - Date.parse(DateParse(trial.start_time)))}</TableCell>
                                <TableCell className="attempt-cell">
                                  <span
                                    className={`pill score ${Number(trial.score) >= 4 ? "pill-passed" : "pill-failed"}`}
                                  >
                                    {trial.score + " / 5"}
                                  </span>
                                </TableCell>
                                <TableCell className="attempt-cell">
                                   <Typography className="view-report-link"
                                    onClick={() => handleReportClick(trial.id)}
                                    >
                                   View Report
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    <Pagination
      count={Math.ceil(trainingScenarios.length / rowsPerPage)} 
      page={currentPage}
      onChange={handlePageChange}
      shape="rounded"
      className="assignment-pagination"
    />
    </>
  );
};

export default AssignmentTable;
