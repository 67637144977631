import { EmployeeInfo } from '../types/EmployeeInfo';
import { SAMPLEASSIGNMENTS } from './SampleAssignments';

export const SAMPLEEMPLOYEES: EmployeeInfo[] = [
  {
    name: 'Alice Johnson',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Passed', score: 85 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 78 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Failed', score: 45 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Passed', score: 72 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Not Completed', score: -1 },
    ]
  },
  {
    name: 'Bob Smith',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Passed', score: 88 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 76 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Failed', score: 43 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Passed', score: 90 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Not Completed', score: -1 },
    ]
  },
  {
    name: 'Carol Lee',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Failed', score: 40 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 81 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Passed', score: 75 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Passed', score: 90 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Not Completed', score: -1 },
    ]
  },
  {
    name: 'David Kim',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Passed', score: 79 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Failed', score: 42 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 67 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Passed', score: 85 },
    ]
  },
  {
    name: 'Eva Garcia',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Passed', score: 77 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 88 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Failed', score: 39 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Passed', score: 92 },
    ]
  },
  {
    name: 'Frank Zhou',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Passed', score: 80 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Failed', score: 45 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 75 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Passed', score: 84 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Not Completed', score: -1 },
    ]
  },
  {
    name: 'Grace Yu',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Passed', score: 95 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 70 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Failed', score: 48 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Passed', score: 89 },
    ]
  },
  {
    name: 'Henry Tan',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Failed', score: 30 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 85 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Passed', score: 78 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Passed', score: 67 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Not Completed', score: -1 },
    ]
  },
  {
    name: 'Ivy Wilson',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Passed', score: 90 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 88 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Failed', score: 48 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Passed', score: 75 },
    ]
  },
  {
    name: 'Jack White',
    assignments: [
      { ...SAMPLEASSIGNMENTS[0], status: 'Passed', score: 82 },
      { ...SAMPLEASSIGNMENTS[1], status: 'Failed', score: 40 },
      { ...SAMPLEASSIGNMENTS[2], status: 'Passed', score: 79 },
      { ...SAMPLEASSIGNMENTS[3], status: 'Not Completed', score: -1 },
      { ...SAMPLEASSIGNMENTS[4], status: 'Passed', score: 86 },
      { ...SAMPLEASSIGNMENTS[5], status: 'Not Completed', score: -1 },
    ]
  },
];
