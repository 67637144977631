import { useState, useRef } from "react";
import { Buffer } from 'buffer';
import { WaveFile } from 'wavefile';

export function useMicrophoneRecorder(end: { (botBuffer: Buffer): Promise<void>; }) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState<Buffer | null>(null);
  const [error, setError] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorderRef.current = mediaRecorder;
      setIsRecording(true);

      mediaRecorder.ondataavailable = async (event) => {
        const arrayBuffer = await event.data.arrayBuffer();
        const audioContext = new AudioContext();
      
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const originalSampleRate = audioBuffer.sampleRate;
      
        const monoData = audioBuffer.numberOfChannels > 1
          ? convertToMono(audioBuffer)
          : audioBuffer.getChannelData(0);
      
        const targetSampleRate = 16000;
        const resampledBuffer = await resampleAudio(monoData, originalSampleRate, targetSampleRate);
      
        const pcmData = normalizeTo16BitPCM(resampledBuffer);
      
        // Prepare WAV encoding
        const wav = new WaveFile();
        wav.fromScratch(
          1,                // Mono
          targetSampleRate, // Resampled rate
          "16",             // Bit depth
          [pcmData]         // PCM data
        );
      
        const wavBuffer = wav.toBuffer();
        setAudioBuffer(Buffer.from(wavBuffer));
        end(Buffer.from(wavBuffer));
      };

      mediaRecorder.onstop = () => {
        setIsRecording(false);
        mediaRecorderRef.current = null;
      };

      mediaRecorder.onerror = (e: Event) => {
        setError(`MediaRecorder error: ${e}`);
      };

      mediaRecorder.start(); // Start recording
    } catch (e) {
      setError(`Microphone access error: ${e}`);
    }
  };

  function convertToMono(audioBuffer: AudioBuffer) {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;
    const monoData = new Float32Array(length);
  
    for (let i = 0; i < numberOfChannels; i++) {
      const channelData = audioBuffer.getChannelData(i);
      for (let j = 0; j < length; j++) {
        monoData[j] += channelData[j] / numberOfChannels;
      }
    }
  
    return monoData;
  }
  
  async function resampleAudio(
    data: Float32Array,
    originalSampleRate: number,
    targetSampleRate: number
  ): Promise<Float32Array> {
    const offlineAudioContext = new OfflineAudioContext(1, (data.length * targetSampleRate) / originalSampleRate, targetSampleRate);
    const audioBuffer = offlineAudioContext.createBuffer(1, data.length, originalSampleRate);
  
    audioBuffer.copyToChannel(data, 0);
  
    const source = offlineAudioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineAudioContext.destination);
    source.start(0);
  
    const renderedBuffer = await offlineAudioContext.startRendering();
    return renderedBuffer.getChannelData(0);
  }

  // Function to normalize Float32 data to 16-bit PCM
  function normalizeTo16BitPCM(float32Array: string | any[] | Float32Array) {
    const pcm16Array = new Int16Array(float32Array.length);
    for (let i = 0; i < float32Array.length; i++) {
      let sample = float32Array[i];
      sample = Math.max(-1, Math.min(1, sample)); // Clamp value to [-1, 1]
      pcm16Array[i] = sample < 0 ? sample * 0x8000 : sample * 0x7FFF; // Scale to 16-bit
    }
    return pcm16Array;
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop(); // Stop recording
    }
  };

  return {
    isRecording,
    error,
    startRecording,
    stopRecording,
    audioBuffer
  };
}