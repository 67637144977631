// ProgressPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTrials } from '../../../../context/TrialContext';
import { getFeedback } from '../../../../services/feedbackService';
import { Feedback } from '../../../../types/Feedback';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen'; // Import the LoadingScreen component

export const ProgressPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { getTrial } = useTrials();
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [localLoading, setLocalLoading] = useState(true); // Local loading state

  const trial = getTrial(id!);

  useEffect(() => {
    const init = async () => {
      setLocalLoading(true);  // Start the local loading state
      try {
        if (trial) {
          const fetchedFeedback = await getFeedback(trial.feedback_id);
          setFeedback(fetchedFeedback);
        }
      } finally {
        setLocalLoading(false);  // End the local loading state
      }
    };
    init();
  }, [trial]);

  if (localLoading) {
    return <LoadingScreen />; // Render local loading screen if still loading
  }

  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column", textAlign: 'left', maxWidth: "50%" }}>
      <h2>{trial?.training_title}</h2>
      <p style={{ margin: 0 }}>Score: {feedback?.overall_score}</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SupportAgentIcon />
        <p style={{ display: "inline", margin: 0, paddingLeft: "8px" }}>{trial?.agent_name}</p>
      </div>
      <div style={{ marginBottom: '30px', display: "flex", alignItems: "center" }}>
        <EventAvailableIcon />
        <p style={{ display: "inline", margin: 0, paddingLeft: "8px" }}>{trial?.complete_date}</p>
      </div>
      
      {feedback?.detailed_comment && Object.entries(feedback.detailed_comment).map(([category, metrics]) => (
        <div key={category}>
          <h3>{category}</h3>
          {Object.entries(metrics).map(([metric, {score, comment}]) => (
            <div style={{ display: "flex", gap: "10px" }} key={metric}>
              <h4>{score}/5</h4>
              <div>
                <h4>{metric}</h4>
                <p>{comment}</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
