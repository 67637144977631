import React from 'react';

function Library() {
  return (
    <div>
      <h1>Library</h1>
    </div>
  );
}

export default Library;
