import { TrainingScenario } from "../types/TrainingScenario";
import axiosClient from "./axiosClient";

export const getByUser = async (userId: string): Promise<TrainingScenario[]> => {
    const response = await axiosClient.get<TrainingScenario[]>('/trainingScenario/byUser',
        {
            params: {
                "user_id": userId
            }
        }
    );
    return response.data;
}

export const getByInstitution = async (instutitionId: string): Promise<TrainingScenario[]> => {
    const response = await axiosClient.get<TrainingScenario[]>('/trainingScenario/byInstitution',
        {
            params: {
                "institution_id": instutitionId
            }
        }
    );
    return response.data;
}

export const getByTraining = async (trainingId: string): Promise<TrainingScenario[]> => {
    const response = await axiosClient.get<TrainingScenario[]>('/trainingScenario/byTraining',
        {
            params: {
                "training_id": trainingId
            }
        }
    );
    return response.data;
}