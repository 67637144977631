import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Trial } from '../types/Trial';
import { getAllTrials } from '../services/trialService';

interface TrialContextType {
    trials: Trial[] | null;
    getTrial: (id: string) => Trial | undefined;
    refetchTrials: () => Promise<void>; 
}

const TrialContext = createContext<TrialContextType | undefined>(undefined);

export const TrialProvider = ({ children }: { children: ReactNode }) => {
    const [ trials, setTrials ] = useState<Trial[] | null>(null);

    const fetchTrials = async () => {
        const trialsResult = await getAllTrials();
        setTrials(trialsResult);
    };

    useEffect(() => {
        fetchTrials();
    }, []);

    const getTrial = (id: string) => {
        const result = trials?.find(trial => trial.id === id);
        return result;
    }

    const refetchTrials = async () => {
        await fetchTrials(); // Explicitly trigger trial fetching
    };

    return (
        <TrialContext.Provider value={{ trials, getTrial, refetchTrials }}>
            {children}
        </TrialContext.Provider>
    );
}

export const useTrials = () => {
    const context = useContext(TrialContext);
    if (!context) {
        throw new Error("useTrials must be used within a Trial Provider");
    }
    return context;
}
