export const AgentSidebarItems = [
  { text: "Dashboard", path: "/pages/dashboard" },
  { text: "Assigned Training ", path: "/pages/assignments" },
  { text: "Training Library", path: "/pages/library" },
  { text: "Progress", path: "/pages/progress" },
  { text: "Settings", path: "/pages/settings" }
];

export const ManagerSidebarItems = [
  { text: "Overview", path: "/pages/overview" },
  { text: "Manage Assignments ", path: "/pages/manage-assignments" },
  { text: "Reports", path: "/pages/reports" },
  { text: "Team", path: "/pages/team" },
];
