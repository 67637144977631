// GlobalLoadingOverlay.tsx
import React from 'react';
import { useLoadingContext } from '../../context/LoadingContext';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

const GlobalLoadingOverlay: React.FC = () => {
  const { globalLoading } = useLoadingContext();

  if (!globalLoading) return null;
  return <LoadingScreen />;
};

export default GlobalLoadingOverlay;
